import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Row from "../components/Row";
import Col from "../components/Col";
import Container from "../components/Container";
import Section from "../components/Section";
import Title from "../components/Title";
import Paragraph from "../components/Paragraph";

const Terms = () => {
  return (
    <Layout>
      <Seo title="Terms & Conditions" />
      <Section>
        <Container>
          <Row justify="center">
            <Col lg={8}>
              <Center>
                <Title size={1} mb={8}>
                  Terms & Conditions
                </Title>
              </Center>
              <Paragraph>
                You have entered the site of the TechKosmo. By using our site,
                you accept and agree to be bound by the terms and conditions
                hereinbelow stated, which may be updated or revised any time.
              </Paragraph>
              <Paragraph>
                By accessing this web site, you are agreeing to be bound by
                these web site Terms and Conditions of Use, all applicable laws
                and regulations, and agree that you are responsible for
                compliance with any applicable local laws. If you do not agree
                with any of these terms, you are prohibited from using or
                accessing this site.
              </Paragraph>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

const Center = styled.div`
  text-align: center;
`;

export default Terms;

import styled from "styled-components"

const Paragraph = styled.p`
  line-height: 1.8;
  margin-bottom: 0;

  & + p {
    margin-top: 0.75rem;
  }
`
export default Paragraph
